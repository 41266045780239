







































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BasicOption, ReviewForm } from '../../types/questions.type';

@Component
export default class QuestionReviewForm extends Vue {
  @Prop(Array) readonly evaluationItems!: BasicOption[];

  @Prop(Object) readonly reviewForm!: ReviewForm;

  validForm = false;

  rules = {
    feedback: [
      (val: string) => val.trim().length <= 500 || this.$t('validations.maxLength', { max: 500 }),
    ],
  };

  @Watch('validForm')
  onValidationChange(newVal) {
    this.$emit('validationChange', newVal);
  }
}
























































































































































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import ScopeComponent from '@/components/scope.component.vue';
import ReviewQuestionsProvider from '../../providers/reviewQuestions.provider';

import {
  ReviewForm,
  BasicOption,
  QuestionHistory,
  DetailedQuestion,
  QuestionDetailsResponse,
} from '../../types/questions.type';
import { Lang } from '../../types/general.type';
import { APPIDS, QUESION_TYPES, REVIEW_QUESTIONS_ROUTES_NAMES } from '../../enums';
import QuestionView from '../questions/QuestionView.component.vue';
import QuestionReviewForm from './QuestionReviewForm.component.vue';
import SimulationDialogComponent from '../questions/QuestionSimulation.dialog.vue';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    QuestionView,
    DialogComponent,
    QuestionReviewForm,
    ScopeComponent,
    SimulationDialogComponent,
  },
})
export default class QuestionReview extends mixins(Toaster) {
  appId = APPIDS.REVIEW_QUESTIONS;

  name = 'questionReview';

  courseId = '';

  questionId = '';

  questionType = '';

  loading = true;

  validForm = true;

  reviewLoading = false;

  showBackConfirm = false;

  showReviewConfirm = false;

  serverError = null;

  reviewTypeEnum = {
    APPROVE: 'approve',
    REJECT: 'reject',
  };

  currentChosenReviewType = '';

  rights: string[] = [];

  question: DetailedQuestion = {};

  evaluationItems: BasicOption[] = [];

  questionHistory: QuestionHistory[] = [];

  reviewQuestionForm: ReviewForm = {
    feedback: '',
    evaluations: [],
  };

  $refs!: {
    simulationDialog: SimulationDialogComponent;
  };

  mounted() {
    this.courseId = this.$route.params.courseId;
    this.questionId = this.$route.params.questionId;
    this.questionType = this.$route.params.questionType;
    this.getQuestionDetails();
  }

  get isCaseStudy() {
    return this.questionType === QUESION_TYPES.CASE_STUDY.id;
  }

  showConfirmDialog(chosenReviewType: string) {
    this.currentChosenReviewType = chosenReviewType;
    this.showReviewConfirm = true;
  }

  setFormValidation(validation: boolean) {
    this.validForm = validation;
  }

  goBack() {
    this.showBackConfirm = false;
    const appFilters = this.$store.state.filters.appFilters[this.appId];
    this.$router.push({
      name: REVIEW_QUESTIONS_ROUTES_NAMES.LIST,
      query: appFilters,
      params: {
        courseId: this.courseId,
      },
    });
  }

  async getQuestionDetails() {
    this.serverError = null;
    try {
      const {
        question,
        evaluationItems,
        rights,
        questionHistory,
      }: QuestionDetailsResponse = await ReviewQuestionsProvider.getQuestionDetails(
        this.appId,
        this.courseId,
        this.questionId,
        { questionType: this.questionType },
      );
      this.rights = rights as string[];
      this.question = question as DetailedQuestion;
      this.questionHistory = questionHistory as QuestionHistory[];
      const { evaluationsIds, mappedEvaluations } = this.formalizeEvaluations(
        evaluationItems,
      );
      this.evaluationItems = mappedEvaluations;
      this.reviewQuestionForm.evaluations = evaluationsIds;
      this.loading = false;
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
      this.serverError = err;
      this.loading = false;
    }
  }

  formalizeEvaluations(evaluationItems) {
    return evaluationItems.reduce(
      (acc, evalItem) => {
        if (!evalItem.id) {
          return acc;
        }
        const parsedId = evalItem.id.toString();
        acc.evaluationsIds.push(parsedId);
        acc.mappedEvaluations.push({ ...evalItem, id: parsedId });
        return acc;
      },
      {
        evaluationsIds: [] as string[],
        mappedEvaluations: [] as BasicOption[],
      },
    );
  }

  async reviewQuestion() {
    try {
      const reqData = { ...this.reviewQuestionForm };
      this.reviewLoading = true;

      await ReviewQuestionsProvider.reviewQuestion(
        this.appId,
        this.courseId,
        this.questionId,
        this.questionType,
        this.currentChosenReviewType,
        reqData,
      );
      this.reviewLoading = false;
      this.showReviewConfirm = false;
      this.goBack();
    } catch (err) {
      this.reviewLoading = false;
      this.showReviewConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  opensimulationDialogDialog() {
    this.$refs.simulationDialog.open();
  }
}
